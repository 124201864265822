import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import LaLomitaFullLogo from '../Interface/LaLomitaFullLogo';

  export default function Footer() {
    const [settings, setSettings] = useState({});
    
    useEffect(() => {
      const fetchSettings = async () => {
        try {
          const response = await fetch('https://api.espaciolalomita.com/api/settings');
          const data = await response.json();
          if (data.length > 0) {
            setSettings(data[0]);
          }
        } catch (err) {
          console.error('Error fetching Settings:', err);
        }
      };
  
      fetchSettings();
    }, []);
  
    return (
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 6 },
          py: { xs: 6, sm: 8 },
          textAlign: 'center',
        }}
      >
        <LaLomitaFullLogo
          sx={{
            width: {
              xs: '150px', // Adjusted for better proportions
              sm: '200px',
            },
            height: 'auto',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 4,
            px: { xs: 2, sm: 0 },
          }}
        >
          <Box sx={{ textAlign: { xs: 'center', sm: 'left' }, mb: { xs: 4, sm: 0 } }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>Contacto</Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Dirección: {settings.direccion}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              Teléfono: {settings.telefono}
            </Typography>
            <Typography variant="body2">
              Email: <Link href={`mailto:${settings.email}`}>{settings.email}</Link>
            </Typography>
          </Box>
          <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>Redes Sociales</Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              sx={{ color: 'text.secondary' }}
            >
              <IconButton
                color="inherit"
                href={`https://api.whatsapp.com/send?phone=541135416036&text=${encodeURIComponent(settings.whatsapp)}`}
                aria-label="Whatsapp"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'success.main',
                  },
                }}
              >
                <WhatsappIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href={settings.facebook}
                aria-label="Facebook"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'primary.main',
                  },
                }}
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                color="inherit"
                href={settings.instagram}
                aria-label="Instagram"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'error.main',
                  },
                }}
              >
                <InstagramIcon />
              </IconButton>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pt: 2,
            width: '100%',
            borderTop: '1px solid',
            borderColor: 'divider',
            typography: 'body2',
            color: 'text.secondary',
          }}
        >
          <Typography>
            Desarrollado por&nbsp;
            <Link
              variant="body2"
              href="mailto:devnogard@gmail.com"
              sx={{ fontWeight: 'bold', '&:hover': { textDecoration: 'underline' } }}
            >
              Nogard
            </Link>
          </Typography>
        </Box>
      </Container>
    );
  }
  